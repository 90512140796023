<template lang="html">
  <div
    v-show="isActive && render"
    class="tab"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Tab',

  props: {
    title: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    order: {
      type: Number,
      default: 0,
    },

    count: {
      type: [Boolean, Number],
      default: false,
    },

    render: {
      type: Boolean,
      default: true,
    },

    textKey: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isActive: false,
  }),

  watch: {
    isActive() {
      this.$emit('input', this.isActive);
    },
  },
};
</script>


<style lang="scss" scoped>

.tab {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @include scrollbar();
}

</style>
