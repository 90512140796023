<template>
  <div class="list-container">
    <div v-if="canDisable">
      <list-options
        :enable-text="$t('participantlist_enable')"
        :disable-text="$t('participantlist_disable')"
        :enable-copy="$t('participantlist_enable_copy')"
        :active="active"
        @toggle="$emit('toggle-list')"
      >
        <template slot="header">
          <template v-if="active">
            {{ $t('participantlist_visible') }}
          </template>
          <template v-else>
            {{ $t('participantlist_hidden') }}
          </template>
        </template>
      </list-options>
    </div>

    <participant-list @toggle-permission="callback" />
  </div>
</template>

<script>
import ListOptions from '@/components/common/ListOptions';

export default {
  components: {
    ListOptions,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    canDisable: {
      type: Boolean,
      required: true,
      default: false,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

  .list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;

    &:before {
      content: "";
      position: absolute;
      background: linear-gradient(#ffffff 50%, rgba(255,255,255,0.3));
      left: 0;
      top: 0;
      height: 1rem;
      margin-top: -0.5rem;
      width: 100%;
    }

    .participant-list {
      list-style-type: none;
      padding: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      margin: 0 0 10px 0;
      @include scrollbar();

      .participant-list__item {
        order: 2;

        &.me {
          order: 0;
        }

        &.hand {
          order: 1;
        }
      }
    }

    &__button {
      align-self: flex-end;
      flex: 0;
    }

  }
</style>
