<template>
  <div
    class="sidebar"
    :class="{ 'closed': !isOpen }"
  >
    <div
      :id="getGtmId('toggle_sidebar')"
      class="sidebar__draggable"
      @click="$emit('toggle')"
    >
      <div class="sidebar__draggable-arrow" />
    </div>
    <div class="sidebar__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',

  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },

};
</script>

<style lang="scss" scoped>

.sidebar {
  display: flex;
  max-width: 100%;
  width: 100%;
  background: white;
  z-index: 4;
  flex-basis: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  flex: 0;
  transition: all 0.5s ease-out;

  @include breakpoint(medium) {
    position: absolute;
    z-index: 3;
    // remove because it breaks the layout if errors bar is showing
    // see more info: https://jira.avantgarde-digital.de/browse/AWC-662
    // min-height: calc(100vh - #{$header-height});
    // min-height: calc((var(--vh, 1vh) * 100) - #{$header-height});
    position: relative;
    max-width: calc(100% - #{$sidebar-draggable-width});
  }

  &.closed {
    max-width: 0;
    min-width: 0;

    @include breakpoint(medium) {
      min-width: #{$sidebar-draggable-width};
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: $sidebar-width--mobile;
    min-width: $sidebar-width--mobile;

    @include breakpoint(medium) {
      min-width: calc(#{$sidebar-width} - #{$sidebar-draggable-width});
    }
  }

  &__draggable {
    display: none;
    cursor: pointer;
    position: relative;
    align-items: center;
    box-shadow: -2px 0px 5px 1px rgba(0,0,0,0.2);
    background-color: $color-darkergrey;

    @include breakpoint(medium) {
      display: flex;
      flex: 0 0 1.5rem;
    }

    &-arrow {
      width: 100%;

      &:after {
        content: "";
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-45deg);
        left: 4px;
        position: relative;
      }
    }
  }

  &.closed &__draggable-arrow {
    transform: scale(-1);
  }
}

</style>
