<template>
  <div class="vod-container">
    <vod-list />
  </div>
</template>


<script>
export default {
  name: 'Vod',

  props: {
    url: {
      type: [String, Boolean],
      default: '',
    },

    active: {
      type: Boolean,
      default: false,
    },

    canDisable: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

.vod-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem;
}
</style>
