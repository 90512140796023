<template>
  <ConfirmDialog
    @close-modal="$emit('close-modal')"
  >
    <template v-slot:title>
      {{ $t('participant_ban_title') }}
    </template>

    <template v-slot:subtitle>
      {{ $t('participant_ban_text') }}
    </template>

    <template v-slot:actions>
      <div class="actions">
        <Button
          :id="getGtmId('participant_ban_action_accept')"
          centered
          :label="$t('participant_ban_action_accept')"
          @click="banAttendee"
        />

        <Button
          :id="getGtmId('participant_ban_action_cancel')"
          centered
          type="secondary"
          :label="$t('participant_ban_action_cancel')"
          @click="$emit('close-modal')"
        />
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import Button from '@/components/common/Button';
import ConfirmDialog from '@/components/common/ConfirmDialog';

export default {
  components: {
    Button,
    ConfirmDialog,
  },

  methods: {
    banAttendee() {
      this.$emit('ban-attendee');
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss">
  .actions {
    display: flex;

    button {
      flex-grow: 1;

      &:first-of-type {
        margin-right: .5rem;
      }
    }
  }
</style>
