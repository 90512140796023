/* eslint-disable max-len */
<template>
  <div class="chat-container">
    <div v-if="canDisable">
      <list-options
        :enable-text="$t('chat_enable')"
        :disable-text="$t('chat_disable')"
        :enable-copy="$t('chat_enable_copy')"
        :active="active"
        @toggle="$emit('toggle-chat')"
      >
        <template slot="header">
          <template v-if="active">
            {{ $t('chat_visible') }}
          </template>
          <template v-else>
            {{ $t('chat_hidden') }}
          </template>
        </template>
      </list-options>
    </div>

    <ChatWindow
      v-if="participationToken && nickname"
      ref="chat"
      :socket-url="socketUrl"
      :user-jwt="participationToken"
      :username="nickname"
      autoscroll
      @chat-error="errorHandler"
      @user-joined="userJoinedHandler"
      @user-left="userLeftHandler"
      @message-sent="messageSentHandler"
      @message-recieved="messageRecievedHandler"
      @chat-initialized="chatInitializedHandler"
    />
  </div>
</template>


<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ListOptions from '@/components/common/ListOptions';

export default {
  name: 'Chat',

  components: {
    ListOptions,
  },

  props: {
    socketUrl: {
      type: String,
      default: '',
    },

    active: {
      type: Boolean,
      default: false,
    },

    currentView: {
      type: Boolean,
      default: false,
    },

    canDisable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('AWCContacts', ['me']),
    ...mapState(['participationToken']),
    ...mapState('ChatMessages', ['unreadCounter', 'messageMenuId', 'isScrolled']),
    nickname() {
      const { nickname } = this.me;
      return nickname;
    },
  },

  watch: {
    currentView() {
      if (this.unreadCounter > 0 && this.currentView) this.resetUnread();
      if (this.$refs.chat) this.$refs.chat.scrollChat(true);
    },
    isScrolled(val) {
      if (!val) this.resetUnread();
    },
    messageMenuId(val) {
      if (!val) return;
      this.$nextTick(() => this.repositionMenu());
    },
  },

  mounted() {
    this.log('Chat: chat mounted with username ', this.nickname);
  },

  destroyed() {
    const messageBox = document.querySelector('#messages');
    if (!messageBox) return;
    messageBox.removeEventListener('scroll', this.repositionMenu);
  },

  methods: {
    ...mapMutations('ChatMessages', ['resetUnread', 'incrementUnread']),

    repositionMenu() {
      const element = document.querySelector('.message-menu');
      if (!element) return;
      element.style.top = element.parentNode.getBoundingClientRect().top;
      element.style.position = 'fixed';
    },

    chatInitializedHandler() {
      this.log('Chat: chatInitialized', this.nickname);
      this.$refs.chat.attemptSetUsername(this.nickname);
      document.querySelector('#messages').addEventListener('scroll', this.repositionMenu);
    },

    messageRecievedHandler(msg) {
      this.log('Chat: messageRecievedHandler', msg);
      if (!this.currentView || this.isScrolled) this.incrementUnread();
    },

    messageSentHandler(msg) {
      this.log('Chat: messageSentHandler', msg);
    },

    userJoinedHandler(msg) {
      this.log('Chat: userJoinedHandler', msg);
    },

    userLeftHandler(msg) {
      this.log('Chat: userLeftHandler', msg);
    },

    errorHandler(msg) {
      this.logError('Chat: errorHandler', msg);
    },
  },
};

</script>

<style lang="scss">

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .list-options__wrapper {
    padding: 1rem;
  }

  .chat {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__button {
    align-self: flex-end;
  }
}

.chat-area {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    background: linear-gradient(#ffffff 50%, rgba(255,255,255,0.3));
    left: 0;
    top: 0;
    height: 1rem;
    z-index: 10;
    margin-top: -0.5rem;
    width: 100%;
  }
}

.chat__scroll-icon {
  cursor: pointer;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-20px, 0);
  width: 40px;
  z-index: 10000000;
  height: 40px;
  background: white;
  color: black;
  padding: .5rem .25rem;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;

  .chat-area.scrolled & {
    visibility: visible;
    opacity: 0.8;
  }
}

.chat__messages {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  flex-direction: column;
  padding: 1rem;
  height: calc(100% - 68px);
  display: flex;
  flex-grow: 1;

  @include scrollbar();
}

.message {
  width: 100%;
  text-align: left;
  display: inline-block;
  display: inline-block;
  border-radius: 3px;
  font-size: $font-size-xs;
  position: relative;

  .ellipsis {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    align-self: center;
    background-color: #979797;
    box-shadow: 6px 0px 0px #979797, 12px 0px 0px #979797;
  }

  .message-container {
    width: 90%;
    margin-top: 0.75rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding: 3px 8px;

    p {
      margin: 0 3px;
    }
  }

  .chat__header {
    display: flex;
    margin-bottom: 4px;
    align-items: flex-start;
    justify-content: space-between;
    color: $color-light-grey;

    p {
      margin: 0;
    }

    &--username {
      margin-right: 15px;
      font-weight: bold;
      line-height: 1.2;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--timestamp {
      flex-grow: 1;
      font-size: .55rem;
      text-align: right;
      padding-right: 0.25rem;
      padding-top: .1rem;
    }

    &--actions {
      margin: 5px 0 0 4px;
      min-height: 15px;
      min-width: 15px;
      cursor: pointer;
    }
  }

  .chat__message {
    background: $color-lighter-grey;
    text-align: left;
    display: inline-block;
    padding: 1rem 1.25rem;
    display: inline-block;
    width: 100%;
    border-radius: 3px;

    &--content p {
      margin: 8px 0 0;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: break-spaces;

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .emoji {
      vertical-align: middle;

      &-0 {
        .emoji {
          font-size: 2rem;
        }
      }

      &-1 {
        .emoji {
          font-size: 1.25rem;
        }
      }
    }

    .content__timestamp {
      margin: 0;
    }
  }

  &.mine {
    .message-container {
      margin-left: 10%;
      padding-right: 5%;
    }

    .chat__message {
      background: black;
      color: white;
      align-self: flex-end;
    }

    .ellipsis {
      background-color: #ffffff;
      box-shadow: 6px 0px 0px #ffffff, 12px 0px 0px #ffffff;
    }
  }

  &.deleted {
    .content__text {
      font-style: italic;
      text-decoration: line-through;
    }
  }
}

.message--reply {
  display: flex;
  padding: .25rem 0;
  height: fit-content;
  background-color: transparent;
}

.reply__divider {
  min-width: 4px;
  min-height: 100%;
  border-radius: 2px;
  margin-right: 12px;
  background-color: #595959;
}

.reply__header {
  display: flex;
  justify-content: space-between;
}

.reply__content {
  flex-grow: 1;
  margin: 4px 0;
}

  .reply__actions {
    cursor: pointer;
  }

  .reply__username,
  .reply__message {
    margin: 0;
    font-size: .75rem;
    padding-right: .5rem;
  }

  .reply__username {
    overflow: hidden;
    width: 100%;
    font-weight: bold;
  }

.message-menu {
  width: 225px;
  border-radius: 4px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
  position: absolute;
  margin-top: calc(0.75rem + 40px);
  right: 5%;
  z-index: 2;
  font-size: 11px;

  .message-menu--reply,
  .message-menu--delete,
  .message-menu--block {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 14px;

    p {
      margin: 0 0 0 10px;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .message-menu--delete,
  .message-menu--block {
    color: #a63100;
  }

  .message-menu--reply {
    border-bottom: 1px solid #c0c0c0;
  }
}

.chat__controls-reply {
  z-index: 1;
  width: 100%;
  bottom: 60px;
  position: absolute;
  background-color: white;
}

.message-input {
  min-height: 40px;
  padding: 8px 16px;
  position: absolute;
  bottom: 0;
  width: 100%;

  .is-typing {
    width: 40px;
    height: 24px;
    display: flex;
    border-radius: 4px;
    padding: 10px 9px;
    background-color: #f1f1f1;
    justify-content: space-between;
  }

    .oval {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #404040;
    }

    .oval:nth-of-type(2) {
      opacity: 0.8;
    }

    .oval:nth-of-type(3) {
      opacity: 0.5;
    }

  .validation-error {
    font-size: .75rem;
    color: #c6000a;
  }

  &__input {
    flex-grow: 1;

    textarea {
      border: 0;
      width: 100%;
      outline: none;
      padding: .75rem .5rem 0;
      font-size: 16px;
      max-height: 40px;
      resize: none;
    }
  }

  &__submit {
    button {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 448'%3E%3Cpath d='M.213 32L0 181.333 320 224 0 266.667.213 416 448 224z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
    }
  }

  &__emoji {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    border-bottom: 2px solid;
  }
}

  .chat-emoji {
    bottom: 56px;
    position: absolute;
  }

</style>
