<template>
  <div class="tabs">
    <div :class="[`tabs__header active-${selectedIndex} items-${tabs.length}`]">
      <div
        v-for="(tab, idx) in tabs"
        :id="getGtmId(getElementId(tab))"
        :key="idx"
        class="tabs__header-item"
        :style="{ order: tab.order }"
        :class="[
          getElementId(tab).toLowerCase(),
          { 'tabs__header-item--active' : selectedIndex === idx }
        ]"
        @click="selectTabByKey(tab.textKey)"
      >
        <div class="tabs__header-item-inner">
          <slot
            name="tab-title"
            v-bind="tab"
          >
            <span>{{ tab.title }}</span>
          </slot>
        </div>
      </div>
    </div>
    <div class="tabs__tab-content">
      <slot />
    </div>
  </div>
</template>


<script>
import { orderBy } from 'lodash';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Tabs',

  props: {
    selected: {
      type: Number,
      default: 0,
    },

    selectedKey: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    selectedIndex: 0,
    tabs: [],
  }),

  computed: {
    ...mapGetters('AWCContacts', ['me']),
  },

  watch: {
    selectedKey(val) {
      this.selectTabByKey(val);
    },
  },

  created() {
    this.tabs = this.$children;
  },

  mounted() {
    this.selectTab(this.selected);
  },

  methods: {
    ...mapMutations(['setSidebarActiveTab']),

    selectTabByKey(key) {
      let id = 0;

      this.tabs.forEach((tab, idx) => {
        if (tab.textKey === key) {
          id = idx;
        }
      });

      this.selectTab(id);
      this.setSidebarActiveTab(key);
    },

    selectTab(i) {
      this.selectedIndex = i;

      this.tabs.forEach((tab, idx) => {
        tab.isActive = (idx === i);
      });
    },

    mapChildren() {
      this.tabs = orderBy(this.$children.filter(child => child.render), ['order'], ['asc']);
      if (this.me.isPresenter) return;

      // tab selection for attendees when tabs where removed/added by presenters
      if (this.tabs.find(tab => tab.textKey === this.selectedKey)) {
        this.selectTabByKey(this.selectedKey);
      } else if (this.tabs.length) {
        this.setSidebarActiveTab(this.tabs[0].textKey);
      }
    },

    getElementId(tab) {
      return (tab.title || '')
        .toLowerCase()
        .replace(/[^a-zA-Z ]/g, '')
        .trim()
        .replace(' ', '_');
    },
  },
};
</script>

<style lang="scss" scoped>

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  &__header {
    display: none;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid $color-grey;
    margin: 0 1rem;
    font-size: $font-size-s;
    flex-shrink: 0;

    @include breakpoint(medium) {
      display: flex;
    }

    &:after {
      content: "";
      height: 3px;
      width: 100%;
      background: $color-darkgrey;
      display: block;
      position: absolute;
      bottom: -2px;
      transition: left 0.2s ease;
      z-index: 11;
      border-radius: 20px;
    }

    &.items-3:after {
      width: 33.3%;
    }

    &.items-3.active-0:after {
      left: 0;
    }
    &.items-3.active-1:after {
      left: 33.3%;
    }
    &.items-3.active-2:after {
      left: 66.6%;
    }

    &.items-2:after {
      width: 50%;
    }

    &.items-2.active-0:after {
      left: 0;
    }
    &.items-2.active-1:after {
      left: 50%;
    }
  }

  &__header-item {
    cursor: pointer;
    text-transform: uppercase;
    color: $color-darkgrey;
    padding: 2rem .75rem .5rem 1rem;
    flex: 1;
    text-align: center;
    max-width: 100%;
    opacity: 0.5;
    overflow: hidden;

    &--active {
      opacity: 1;
    }

    &-inner {
      display: flex;
      position: relative;
      max-width: 100%;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      .item-title {
        flex-grow: 1;
      }
    }
  }

  &__tab-content {
    height: 100%;
    overflow: hidden;
  }
}

.new-message__count {
  color: white;
  position: absolute;
  background-color: $color-gold-dark;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: .5rem;
  font-weight: bold;
  flex-shrink: 0;
  left: -1.2rem;
}

</style>
