<template>
  <ConfirmDialog
    @close-modal="$emit('close-modal')"
  >
    <template v-slot:title>
      {{ $t('leave_conversation_headline') }}
    </template>

    <template v-slot:subtitle>
      {{ $t('leave_conversation_text') }}
    </template>

    <template v-slot:actions>
      <Button
        :id="getGtmId('leave_conversation_leave')"
        centered
        :label="$t('leave_conversation_button_label_leave')"
        @click="$emit('exit-room')"
      />

      <Button
        :id="getGtmId('leave_conversation_close')"
        centered
        type="secondary"
        :label="$t('leave_conversation_button_label_close')"
        @click="$emit('close-room')"
      />
    </template>
  </ConfirmDialog>
</template>

<script>
import Button from '@/components/common/Button';
import ConfirmDialog from '@/components/common/ConfirmDialog';

export default {
  components: {
    Button,
    ConfirmDialog,
  },
};
</script>
