<template>
  <div class="list-options__wrapper">
    <icon image="info" />
    <div>
      <slot name="header" />
      <accordion
        class="list-options"
        :full="false"
      >
        <template slot="header">
          <div class="list-options__accordion-header">
            {{ $t('show_options') }}
          </div>
        </template>
        <template>
          <div class="list-options__option accordion__content">
            <div class="list-options__copy">
              <template v-if="!active">
                {{ enableText }}
              </template>
              <template v-else>
                {{ disableText }}
              </template>
              <span>{{ enableCopy }}</span>
            </div>
            <div class="list-options__toggle">
              <toggle
                small
                :value="active"
                @change="$emit('toggle')"
              />
            </div>
          </div>
        </template>
      </accordion>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion';
import Icon from '@/components/common/Icon';
import Toggle from '@/components/common/Toggle';

export default {
  name: 'ListOptions',

  components: {
    Toggle,
    Icon,
    Accordion,
  },
  props: {
    enableText: {
      type: String,
      default: 'enable',
    },
    disableText: {
      type: String,
      default: 'disable',
    },
    enableCopy: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-options {
  font-size: 12px;
  padding-bottom: 1rem;

  &__wrapper {
    display: flex;
    font-size: 14px;
    line-height: 1.2;
    border-bottom: 1px solid rgba(190, 160, 160, 0.28);

    .svg-icon {
      margin-right: .5rem;
      flex-shrink: 0;
      flex-grow: 0;
    }

    > div {
      flex-grow: 1;
    }
  }

  &__option {
    display: flex;
    margin-top: 0.5rem;
  }

  &__copy {
    flex: 1;
    font-size: 13px;
    font-weight: bold;

    span {
      margin-top: .5rem;
      font-size: 11px;
      font-weight: normal;
      display: block;
    }
  }

  &__toggle {
    margin-left: .5rem;
    flex-shrink: 0;
  }
}
</style>
