<template>
  <div class="nickname-form">
    <div class="nickname-form__inner">
      <h2>{{ $t('welcome_to_chat') }}</h2>
      <p class="nickname-form__copy">
        {{ $t('chat_introduction') }}
      </p>

      <input
        v-model="chatNickname"
        type="text"
        class="nickname-form__input"
        maxlength="50"
        name="given-name"
        autocomplete="given-name"
        :placeholder="$t('chat_nickname_placeholder')"
        @keyup.enter="$emit('submit', chatNickname)"
      >

      <app-button
        :id="getGtmId('chat_nickname_submit_label')"
        arrow
        small
        :label="$t('chat_nickname_submit_label')"
        @click="$emit('submit', chatNickname)"
      />

      <a
        :id="getGtmId('submit_form_link')"
        class="nickname-form__link"
        @click="$emit('submit', null)"
      >
        {{ $t('chat_nickname_cancel_label') }}
      </a>

      <transition name="modal">
        <div
          v-if="error"
          class="nickname-form__error"
        >
          {{ error }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/Button';

export default {
  name: 'NicknameForm',

  components: {
    AppButton,
  },

  props: {
    error: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    chatNickname: '',
  }),
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_app.scss';

  .nickname-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width:100%;
    background:white;
    z-index:1;
    text-align: center;
    padding: 4rem 0;

    &__inner {
      max-width: 250px;
      margin: auto;
    }

    .button {
      margin: auto;
    }

    h2 {
      @extend h2;
      text-transform: uppercase;
    }

    &__copy {
      font-size: $font-size-xs;
    }

    &__input {
      margin: 1rem 0 2rem;
      width: 100%;
      line-height: 1.5rem;
      text-transform: none;

      &::placeholder {
        text-transform: none;
      }
    }

    &__link {
      cursor: pointer;
      display: inline-block;
      margin: 1rem 0;
      letter-spacing: .1em;
      font-size: $font-size-xxs;
      text-transform: uppercase;
      font-weight: bold;

      &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        margin-top: 1px;
        border-bottom: 2px solid $color-darkgrey;
      }
    }

    &__error {
      color: $error-red;
      font-size: $font-size-s;
      margin-top: 1rem;
    }
  }
</style>
