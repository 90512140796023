<template>
  <div class="conference-wrapper">
    <ConfirmRoomClosing
      v-if="showRoomClosingModal"
      @close-modal="showRoomClosingModal = false"
      @exit-room="leave"
      @close-room="destroyRoom"
    />

    <ConfirmParticipantBan
      v-if="showBanModal"
      @close-modal="setAttendeeToBan(null)"
      @ban-attendee="banAttendee(attendeeToBan)"
    />

    <modal
      v-if="showBrowserModal"
      :button-arrow="false"
      closable
      centered
      icon="info"
      width="small"
      :confirm-label="$t('message_unsupported_browser_confirm_label')"
      @confirm="showBrowserModal = false"
      @close="showBrowserModal = false"
    >
      {{ $t('message_unsupported_browser_text') }}
    </modal>

    <conference
      v-if="roomID && !showBrowserModal"
      ref="conference"
      :api-key="apiRTCKey"
      :video-supported="videoSupported"
      :audio-supported="audioSupported"
      :show-chat-module="safeGet(room, 'settings.showChatModule', false)"
      :show-participation-list="safeGet(room, 'settings.showParticipationList', false)"
      :show-polling-module="safeGet(room, 'settings.showPollingModule', false)"
      :raise-hand-allowed="safeGet(room, 'settings.raiseHandAllowed', false)"
      :audio-active="audioEnabled"
      :video-active="videoEnabled"
      :participation-token="participationToken"
      :permissions="safeGet(participation, 'permissions')"
      :conversation-id="roomID"
      :nickname="safeGet(participation, 'nickname')"
      :debug-qos-information="debug"
      @error="handleError"
      @connected="onConnected"
      @connection-error="handleConnectionError"
      @apizee-connection-error="handleApiZeeConnectionError"
      @disconnected="onDisconnected"
    >
      <modal
        v-if="showUserGestureHelper"
        :button-arrow="false"
        :closable="false"
        centered
        icon="info"
        width="small"
        :confirm-label="$t('message_interaction_needed_confirm_label')"
        @confirm="playMedia"
      >
        {{ $t('message_interaction_needed_text') }}
      </modal>
      <!-- CONNECTIVITY INDICATOR -->
      <Errors
        class="conference__errors"
        :errors="displayConnectivityErrors()"
      />
      <Errors
        v-if="!loaded3q && videosActive && me.isPresenter"
        class="conference__errors"
        :errors="[this.$t('error_3q_not_loaded')]"
      />
      <div
        class="conference__main"
        :class="{ 'sidebar-open': sidebarOpen }"
      >
        <video-stage
          ref="videoStage1"
          video-priority="screen"
          :show-all="true"
          :show-audio="!interactiveGrid"
          :interactive-grid="interactiveGrid"
          class="conference__video-stage conference__main-video-stage"
          @resize="stageResize"
          @video-count-changed="mainVideoCountChanged"
          @autoplay-error="handleAutoplayError"
        />
        <side-bar
          v-show="sidebarShown"
          :is-open="sidebarOpen"
          class="conference__sidebar"
          @toggle="setSidebarOpen(!sidebarOpen)"
        >
          <tabs
            v-show="me.isPresenter || tabsShown"
            ref="tabs"
            :selected-key="sidebarActiveTab"
          >
            <template v-slot:tab-title="{ title, count }">
              <div
                v-show="count"
                class="new-message__count"
              >
                <span v-text="count > 9 ? '9+' : count" />
              </div>

              <span
                class="item-title"
                v-text="title"
              />
            </template>

            <tab
              :title="`${ $t('tabs_participants_label') } (${contactsCount})`"
              :render="me.isPresenter || showParticipationList"
              :order="0"
              text-key="users"
              :count="handsRaisedCount"
            >
              <Participants
                v-if="me.isPresenter || showParticipationList"
                :can-disable="me.isPresenter"
                :active="showParticipationList"
                :callback="updatePermissions"
                @toggle-list="toggleList"
              />
            </tab>
            <tab
              v-model="chatIsActive"
              text-key="chat"
              :title="$t('tabs_chat_label')"
              :render="me.isPresenter || showChatModule"
              :order="1"
              :count="unreadCounter"
              :show-toggle="me.isPresenter"
              :toggle-value="showChatModule"
              :toggle-callback="toggleChat"
            >
              <transition name="modal">
                <nickname-form
                  v-if="showNicknameChangeOverlay && chatIsActive"
                  :error="chatNicknameError"
                  @submit="changeChatNickname"
                />
              </transition>
              <chat
                v-if="safeGet(participation, 'nickname') && !showNicknameChangeOverlay"
                :active="showChatModule"
                :current-view="chatIsActive && sidebarOpen"
                :can-disable="me.isPresenter"
                :socket-url="socketUrl"
                @toggle-chat="toggleChat"
              />
            </tab>
            <tab
              text-key="poll"
              :title="$t('tabs_poll_label')"
              :render="showPollTab"
              :order="2"
              :show-toggle="me.isPresenter"
              :toggle-value="showPollingModule"
              :toggle-callback="togglePolling"
            >
              <poll
                :active="showPollingModule"
                :can-disable="me.isPresenter"
                :url="pollUrl"
                @toggle-poll="togglePolling"
              />
            </tab>
            <tab
              text-key="vod"
              :title="$t('tabs_vod_label')"
              :render="showVodTab"
              :order="2"
            >
              <vod />
            </tab>
          </tabs>
        </side-bar>

        <!-- hardware settings -->
        <transition :name="isMobile ? 'slide-right' : 'modal'">
          <component
            :is="isMobile ? 'div' : 'modal'"
            v-if="showHardwareSettings"
            :class="isMobile ? 'hardware-test__wrapper' : 'hardware-test__modal'"
            transparent
            width="large"
            closable
            backdrop-close
            @close="setShowHardwareSettings(false)"
          >
            <hardware-test
              class="conference__hardware-test"
              :nickname="safeGet(participation, 'nickname')"
              :can-enable-video="false"
              :can-enable-audio="false"
              :show-nickname="false"
              :show-video="videoSupported"
              :show-audio="audioSupported"
              :show-audio-meter="false"
              :video-block-heading="$t('hardware_test_video_settings_title')"
              :audio-block-heading="$t('hardware_test_audio_settings_title')"
              :quality-block-heading="$t('hardware_test_quality_settings_title')"
              :quality-block-copy="$t('hardware_test_quality_settings_copy')"
              video-block-hint=""
            />
          </component>
        </transition>
      </div>

      <actionbar @force-end="attemptLeaveRoom">
        <!-- TODO: move in actionbar -->
        <action-nav
          :active-item="(sidebarOpen ? sidebarActiveTab : null)"
          @select="onSelectNavItem"
        />
      </actionbar>
    </conference>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Chat from '@/components/Chat';
import ConfirmParticipantBan from '@/components/ConfirmParticipantBan';
import ConfirmRoomClosing from '@/components/ConfirmRoomClosing';
import Errors from '@/components/common/Errors';
import Modal from '@/components/common/Modal';
import NicknameForm from '@/components/NicknameForm';
import Participants from '@/components/Participants';
import Poll from '@/components/Poll';
import Vod from '@/components/Vod';
import SideBar from '@/components/common/SideBar';
import Tab from '@/components/common/Tab';
import Tabs from '@/components/common/Tabs';

import tracking from '@/mixins/tracking';
import ENLIST_ERRORS from '@/util/errors';

export default {
  name: 'Webinar',

  components: {
    Errors,
    ConfirmParticipantBan,
    ConfirmRoomClosing,
    SideBar,
    Tabs,
    Tab,
    Chat,
    Poll,
    Participants,
    Modal,
    NicknameForm,
    Vod,
  },

  mixins: [tracking],

  data: () => ({
    showRoomClosingModal: false,
    showConnectivityError: false,
    chatIsActive: false,
    apiRTCKey: process.env.VUE_APP_APIRTC_KEY,
    joining: true,
    mainVideoCount: 0,
    sidebarVideosCount: 0,
    // nicknameConfirmed: false,
    mainVideoStageDimensions: {},
    showUserGestureHelper: false,
    showBrowserModal: true,
    chatNicknameError: '',
  }),

  computed: {
    ...mapState('AWCUser', ['audioEnabled', 'videoEnabled', 'interactiveGrid']),
    ...mapGetters('AWCUser', ['canSendAudio', 'canSendVideo']),
    ...mapGetters('AWCContacts', ['me']),
    ...mapState('AWCContacts', ['contacts']),
    ...mapState('ChatMessages', ['unreadCounter']),
    ...mapState('AWCView', ['showHardwareSettings', 'loaded3q']),
    ...mapState('AWCConversation', [
      'showParticipationList', 'showPollingModule',
      'showChatModule', 'pollUrl',
    ]),

    ...mapState([
      'participation', 'participationToken',
      'enlistToken', 'room', 'attendeeToBan',
      'sidebarOpen', 'sidebarActiveTab',
      'nicknameConfirmed',
    ]),

    ...mapGetters('AWCConversation', ['showPollTab', 'showVodTab']),
    ...mapGetters('AWCContacts', ['handsRaisedCount']),
    ...mapGetters('AWCUser', ['canChangeName']),
    ...mapGetters(['showBanModal', 'videoSupported', 'audioSupported', 'videosActive']),

    roomID() {
      return this.$route.params.roomID;
    },

    // Had to replace the arrow function with a regular function.
    // Arrow functions do not have this attached.
    debug() {
      return this.$route.query.debug === 'true';
    },

    socketUrl: () => process.env.VUE_APP_CHAT_URL,
    signallingUrl: () => process.env.VUE_APP_SIGNALLING_URL,

    sidebarShown() {
      return this.me.isPresenter || this.sidebarVideosCount
        || this.showParticipationList || this.showChatModule || this.showPollingModule || this.showVodTab;
    },

    tabsShown() {
      return this.showParticipationList || this.showChatModule || this.showPollingModule || this.showVodTab;
    },

    tabState() {
      return [
        this.showParticipationList,
        this.showChatModule,
        this.showPollTab,
        this.showVodTab,
      ];
    },

    contactsCount() {
      return (Object.keys(this.mappedContacts) || []).length + 1;
    },

    showNicknameChangeOverlay() {
      return !this.me.isPresenter
        && !this.canChangeName
        && !this.nicknameConfirmed
        && !this.safeGet(this.participation, 'changedNameAt');
    },

    isMobile() {
      return this.$mq === 'mobile';
    },
  },

  watch: {
    tabState: {
      deep: true,
      handler() {
        this.log('tabStateChange', this.tabState);
        this.refreshTabs();
      },
    },

    room: {
      immediate: true,
      handler(val) {
        if (val) document.title = val.name;
      },
    },
  },

  mounted() {
    this.setSidebarOpen(this.$mq !== 'mobile');
    this.showBrowserModal = !this.$browserDetect.isChrome;
  },

  /*
   * Navigation / Browser back
   * always GLOBALLY DISCONNECT if webinar route is left
   * wait for disconnection in case of /enlist route to prevent nickname conflict
   */
  async beforeRouteLeave(to, from, next) {
    const disconnected = this.globalDisconnect({ complete: true, notify: true });
    if (to.name === 'Entry') await disconnected;

    next();
  },

  methods: {
    ...mapMutations('AWCView', ['setShowHardwareSettings']),
    ...mapActions(['removeParticipation']),
    ...mapMutations(['setSidebarOpen', 'setSidebarActiveTab', 'setAttendeeToBan', 'setNicknameConfirmed']),
    ...mapActions('AWCConversation', ['disconnectConversation']),
    ...mapActions('AWCSignalling', [
      'connectToSignalling', 'toggleChat', 'toggleList',
      'togglePolling', 'updatePermissions', 'authenticateSignalling',
      'closeRoom', 'banAttendee', 'updateNickname',
    ]),

    sidebarVideoCountChanged(count) {
      this.sidebarVideosCount = count;
    },

    mainVideoCountChanged(count) {
      this.mainVideoCount = count;

      this.calculateVideoDimensions();
    },

    stageResize(dimensions) {
      this.mainVideoStageDimensions = dimensions;

      this.calculateVideoDimensions();
    },

    // TODO move to package
    calculateVideoDimensions() {
      const videos = document.querySelectorAll('.conference__main-video-stage .video-stage__video-wrapper-outer');
      if (!this.interactiveGrid) {
        videos.forEach(el => el.style.flexBasis = '');
        return;
      }

      const fullHeight = this.mainVideoStageDimensions.height - 20;
      const fullWidth = this.mainVideoStageDimensions.width - 20;

      const cols = Math.floor(Math.sqrt(this.mainVideoCount));
      const rows = Math.ceil(this.mainVideoCount / cols);

      const maxHeight = fullHeight / rows;
      const maxWidth = fullWidth / cols;

      const maxWidthBasedOnHeight = Math.floor(maxHeight * 1.7777); // 16:9

      let width = maxWidthBasedOnHeight < maxWidth ? maxWidthBasedOnHeight : maxWidth;
      if (maxWidth < width) width = maxWidth;

      videos.forEach(el => el.style.flexBasis = `${width}px`);
    },

    /*
     * Generic Error Handler
     */
    async handleError(error) {
      this.logError(error);
      this.showErrorPage(false);
    },

    /*
     * Error handler for ApiZee Connection Failures
     */
    async handleApiZeeConnectionError(error) {
      this.logError(error);
      this.showErrorPage();
    },

    /*
     * Error handler for WBE Connection Failures (/join error)
     */
    async handleConnectionError(error) {
      this.logError(error);

      if (!error.response) {
        this.showErrorPage();
        return;
      }

      switch (error.response.status) {
        // 403 - Room not started
        case 403:
          this.goBackToLogin(ENLIST_ERRORS.ROOM_NOT_STARTED);
          break;
        // 409 - Nickname Conflict
        case 409:
          // Remove particiation to prevent disconnection
          // of hosts if they are already connected
          // with the same participation on a separate device
          this.removeParticipation();
          this.goBackToLogin(ENLIST_ERRORS.NICKNAME_DUPLICATE);
          break;
        // 404 - Room not found
        case 404:
          this.showErrorPage(false);
          break;
        default:
          this.showErrorPage();
      }
    },

    /*
     * Go to Login Route and show error
     */
    goBackToLogin(error) {
      this.$router.push({ name: 'Entry', query: { token: this.enlistToken }, params: { error } });
    },


    showErrorPage(showBack = true) {
      this.$router.push({ name: 'Error', params: { showBack } });
    },

    async onConnected() {
      this.joining = false;
      this.trackJoin();

      try {
        this.log('onConnected');
        await this.connectToSignalling({
          url: this.signallingUrl,
          closeCallback: this.leave,
          bannedCallback: this.leave,
          // It happens when there is a successfull reconnection attempt
          reconnectedCallback: () => {
            this.showConnectivityError = false;
          },

          // It happens when there is a reconnection attempt error on
          reconnectErrorCallback: () => {
            this.showConnectivityError = true;
          },

          // It happens when there is a reconnection attempt failure
          // getting to this point means that all the reconnection
          // attempts failed
          reconnectFailedCallback: async () => {
            this.goBackToLogin(this.$t('error_signalling_cannot_connect'));
          },
        });

        await this.authenticateSignalling();
        await this.refreshTabs();
      } catch (error) {
        this.handleError(error);
      }
    },

    displayConnectivityErrors() {
      return this.showConnectivityError
        ? [this.$t('error_signalling_trying_to_reconnect')]
        : [];
    },

    /*
     * Called on apiRTC left event
     * trigger global disconnection (signaling, chat, streams, wbe) again to be fail-safe
     */
    onDisconnected() {
      this.log('Webinar: onDisconnected');
      this.globalDisconnect({ complete: true, notify: true });
    },

    /*
     * Leave Conference (intentionally, being banned, room closed)
     */
    async leave() {
      this.log('Webinar: leave');
      if (this.room) this.trackLeave();
      this.$router.push({ name: 'ThankYou' });
    },

    /**
     * TODO: should we move this to the package?
     *
     * Async attempt to reset connections.
     * Closes signalling, chat and apiRTC connections, optionally it can notify the wbe
     * about the disconnection event and remove the participation data from storage.
     *
     * @param { boolean } complete If true it removed the participation data from storage
     * @param { boolean } notify If true it notifies wbe about the disconnection, usually
     * redundant since signalling should handle this. Added here as a fail-safe.
     *
     * @returns Promise
     */
    async globalDisconnect({ complete = false, notify = true }) {
      this.log('Webinar: globalDisconnect');

      try {
        // close WebRTC conversation, signalling, chat and nofity wbe about disconnection.
        const disconnected = this.disconnectConversation({ notify, participationToken: this.participationToken });

        // Remove participation from storage
        if (complete) this.removeParticipation();

        await disconnected;
      } catch (error) {
        this.removeParticipation();
        this.showErrorPage();
      }
    },

    // hate this, but seems to be the only way to have it work.
    refreshTabs() {
      const vm = this;
      setTimeout(() => {
        if (vm.$refs.tabs) {
          vm.log('refreshTabs', vm.$refs.tabs);
          return vm.$refs.tabs.mapChildren();
        }
        return vm.refreshTabs();
      }, 250);
    },

    attemptLeaveRoom() {
      if (this.me.isPresenter) this.showRoomClosingModal = true;
      else this.leave();
    },

    destroyRoom() {
      if (this.me.isPresenter) {
        this.trackAction('room_close');
        // TODO fix closeRoom promise, await
        this.closeRoom();
        this.leave();
      }
    },

    handleAutoplayError() {
      this.showUserGestureHelper = true;
    },

    playMedia() {
      this.showUserGestureHelper = false;
      if (this.$refs.videoStage1) this.$refs.videoStage1.playMedia();
    },

    async changeChatNickname(val) {
      this.log('changeChatNickname', val);
      this.chatNicknameError = '';

      try {
        if (val) {
          await this.updateNickname({ nickname: val });
        }

        this.setNicknameConfirmed(true);
      } catch (error) {
        this.logError('Chat Nickname:', error);
        if (error.code === 409) {
          this.chatNicknameError = this.$t(ENLIST_ERRORS.NICKNAME_DUPLICATE);
        }
      }
    },

    onSelectNavItem(key) {
      if (this.sidebarOpen && this.sidebarActiveTab === key) {
        this.setSidebarOpen(false);
        return;
      }
      this.setSidebarActiveTab(key);
      if (!this.sidebarOpen) this.setSidebarOpen(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_app.scss';

.conference {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__modal {

    /deep/ .modal {
      padding: 2rem;
    }

    &__button {
      width: 100%;
      margin-top: 1rem;
    }
  }

  &__main {
    display: flex;
    flex: 1;
    max-width: 100%;
    position: relative;

    @include breakpoint(small only) {
      overflow: hidden;
    }

    .video-stage {
      width: 100%;

      @include breakpoint(medium) {
        width: calc(100% - #{$sidebar-draggable-width});
      }
    }

    &.sidebar-open {

      .video-stage {
        width: calc(100% -  #{$sidebar-width--mobile});

        @include breakpoint(medium) {
          width: calc(100% -  #{$sidebar-width});
        }
      }

      .sidebar {
        min-width: $sidebar-width--mobile;
        flex-basis: $sidebar-width--mobile;

        @include breakpoint(medium) {
          min-width: #{$sidebar-width};
          flex-basis: #{$sidebar-width};
        }
      }
    }
  }

  /deep/ &__media-error {
    @extend .error-banner;
  }

  /deep/ &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 50%;
    position: absolute;
    transform: translate(0,-50%);
  }

  /deep/ &__loading-spinner {
    width: 78px;
    height: 78px;
    display: inline-block;
    overflow: hidden;
    opacity: 0.5;

    .spinner {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;

      div {
        box-sizing: content-box;
        position: absolute;
        width: 12px;

        &:nth-child(1) {
          left: 17px;
          background: #9d6b25;
          animation: loadingAnimation-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
          animation-delay: -0.2s
        }

        &:nth-child(2) {
          left: 42px;
          background: #d6a76f;
          animation: loadingAnimation-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
          animation-delay: -0.1s
        }

        &:nth-child(3) {
          left: 67px;
          background: #eecda5;
          animation: loadingAnimation-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
          animation-delay: undefined;
        }
      }
    }
  }

  @keyframes loadingAnimation-1 {
    0% { top: 15px; height: 68px }
    50% { top: 31px; height: 38px }
    100% { top: 31px; height: 38px }
  }
  @keyframes loadingAnimation-2 {
    0% { top: 19px; height: 60px }
    50% { top: 31px; height: 38px }
    100% { top: 31px; height: 38px }
  }
  @keyframes loadingAnimation-3 {
    0% { top: 23px; height: 53px }
    50% { top: 31px; height: 38px }
    100% { top: 31px; height: 38px }
  }
}

/deep/ .toggle {
  &__icon-disabled-overlay {
    border-color: white;
  }
}
</style>
