<template>
  <div
    class="confirm-modal"
    @click.self="$emit('close-modal')"
  >
    <div class="confirm-modal__stage">
      <button
        :id="getGtmId('confirm_modal_close')"
        class="confirm-modal__close"
        @click="$emit('close-modal')"
      >
        <Icon
          size="custom"
          image="burger-close"
        />
      </button>

      <h1 class="confirm-modal__title">
        <slot name="title" />
      </h1>

      <h3 class="confirm-modal__subtitle">
        <slot name="subtitle" />
      </h3>

      <div class="confirm-modal__actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },
};
</script>
