<template>
  <div class="poll-container">
    <div v-if="canDisable">
      <list-options
        :enable-text="$t('poll_enable')"
        :disable-text="$t('poll_disable')"
        :enable-copy="$t('poll_enable_copy')"
        :active="active"
        @toggle="$emit('toggle-poll')"
      >
        <template slot="header">
          <template v-if="active">
            {{ $t('poll_visible') }}
          </template>
          <template v-else>
            {{ $t('poll_hidden') }}
          </template>
        </template>
      </list-options>
    </div>

    <iframe :src="url" />
  </div>
</template>


<script>
import ListOptions from '@/components/common/ListOptions';

export default {
  name: 'Poll',

  components: {
    ListOptions,
  },

  props: {
    url: {
      type: [String, Boolean],
      default: '',
    },

    active: {
      type: Boolean,
      default: false,
    },

    canDisable: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

.poll-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem;

  &__options {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(190, 160, 160, 0.28);

  &-copy {
    flex: 1;
    font-size: 13px;
    font-weight: bold;

    span {
      margin-top: .5rem;
      font-size: 11px;
      font-weight: normal;
      display: block;
    }
  }

  &-toggle {
    margin-left: .5rem;
    flex-shrink: 0;
  }
}

  iframe {
    height: 100%;
  }

  &__button {
    align-self: flex-end;
  }
}
</style>
